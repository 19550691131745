<template>
  <QBtn
    v-bind="vBindedProps"
    class="text-body1"
    :label="props.label ? computedLabel(props.label) : undefined"
    ripple
    :disable="disable"
    type="a"
    @click="$emit('click')"
  >
    <!-- Set type=a so tooltip shows even if btn is disabled -->
    <HcTooltip
      v-if="props.tooltip"
      :label="props.tooltip"
    />
  </QBtn>
</template>

<script setup>
import { omit } from 'lodash'
import { computed } from 'vue'
import { HcTooltip } from 'hc-core'
import { computedLabel } from 'hc-core/composables/intl'

const variants = {
  primary: {
    color: 'primary',
    'text-color': 'white',
  },
  secondary: {
    color: 'secondary',
    'text-color': 'black',
  },
  info: {
    color: 'info',
    'text-color': 'black',
    icon: 'uil:info',
  },
  positive: {
    color: 'positive',
    'text-color': 'black',
    icon: 'uil:check',
  },
  negative: {
    color: 'negative',
    'text-color': 'white',
    icon: 'uil:exclamation-octagon',
  },
  warning: {
    color: 'warning',
    'text-color': 'black',
    icon: 'uil:exclamation-triangle',
  },
  flat: {
    'text-color': 'black',
    flat: true,
    dense: true
  },
  disabled: {
    color: 'grey-3',
    'text-color': 'black',
    icon: 'uil:ban'
  }
}

const sizeVariants = {
  sm: {
    size: 'sm',
    padding: 'xs sm',
  },
  md: {
    size: 'md',
    padding: 'sm md',
  },
  lg: {
    size: 'lg',
    padding: 'md lg',
  }
}

const vBindedProps = computed(() =>
  Object.assign(
    {},
    props.withIcon ? variants[props.variant] : omit(variants[props.variant], ['icon']),
    sizeVariants[props.size]
  )
)

const props = defineProps({
  label: [String, Object],
  tooltip: [String, Object],
  variant: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'md'
  },
  disable: {
    type: Boolean,
    default: false,
  },
  withIcon: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['click'])
</script>
