import routes from './routes'
import stelace from 'hc-core/composables/stelace'
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import { pick } from 'lodash'
import { usePerms, useSession, utilsRouting } from 'hc-core'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(function ({ store, ssrContext }) {
  const perms = usePerms(store)
  const session = useSession(ssrContext)

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  if (process.env.CLIENT) {
    Router.beforeEach(async (to, from, next) => {
      // Handler repeated few times
      const tryLoginAs = async (token) => {
        const loginAs = await store.dispatch('auth/loginAs', { token: { type: 'Bearer', ...token } })
        if (loginAs) {
          await store.dispatch('auth/fetchCurrentUser')
          next()
        } else {
          await store.dispatch('auth/logout')
          next({ name: 'login', query: { redirect: utilsRouting.base64Encoder(to) } })
        }
      }

      try {
        const happycabCookie = session.getKey()
        const isAuthenticated = stelace.auth.info().isAuthenticated
        if (isAuthenticated && !store.getters['auth/currentUser']) await store.dispatch('auth/fetchCurrentUser')
        const properlyAuth = isAuthenticated && happycabCookie !== null

        if (to.meta.mustBeLogged && properlyAuth) {
          // Ensure proper account creation for clients
          const currentUser = store.getters['auth/currentUser']
          const currentCompanies = store.getters['auth/uElements']('companies')
          if (currentUser.roles.includes('client') && !currentCompanies.length && to.name !== 'signupCompanyComplete') {
            next({ name: 'signupCompanyComplete' })
          } else if (!to.query.redirect) {
            if (!to.meta.perms || (to.meta.perms && perms.allPermsValid(to.meta.perms))) next()
            else next({ name: 'dashboard' })
          } else next(utilsRouting.base64Decoder(to.query.redirect))
        } else if (to.meta.mustBeLogged && !properlyAuth) {
          // Handle embedOffer
          if (to.name === 'embedOffer' && to.query.userId && to.query.token && to.query.refreshToken) {
            await tryLoginAs(pick(to.query, ['userId', 'accessToken', 'refreshToken']))
          } else if (to.name === 'signupCompanyComplete' && to.query.from && happycabCookie) {
            await tryLoginAs(pick(to.happycabCookie, ['userId', 'accessToken', 'refreshToken']))
          } else {
            next({ name: 'login', query: { redirect: utilsRouting.base64Encoder(to) } })
          }
        } else if (to.meta.mustBeLoggedOut) {
          if (to.name === 'loginAs') {
            await store.dispatch('auth/logout')
            await tryLoginAs({
              userId: to.params.userId,
              ...pick(to.query, ['accessToken', 'refreshToken'])
            })
          } else if (properlyAuth && to.query.redirect) next(utilsRouting.base64Decoder(to.query.redirect))
          else if (properlyAuth && to.name === 'login') next({ name: 'dashboard' })
          else {
            await store.dispatch('auth/logout')
            next()
          }
        } else {
          next()
        }
      } catch (error) {
        await store.dispatch('auth/logout')
        next({ name: 'login' })
      }
    })
  }

  return Router
})
