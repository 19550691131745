import { flattenDeep, get, last } from 'lodash'

export default {
  computed: {
    currentUser () { return this.$store.getters['auth/currentUser'] },
    userOrganizations () { return this.$store.getters['auth/userOrganizations'] },
    currentNaturalUser () { return this.$store.getters['auth/currentNaturalUser'] },
  },
  methods: {
    // Old way, maybe be slowly replaced by usePerm
    hasRole (user, role) {
      return user && role && get(user, 'roles', []).includes(role)
    },

    // Only in organization context
    isSubscribedOrganization () {
      return get(this.userOrganizations, 'subscription', false)
    },

    // General roles
    isApplicant (user = this.currentNaturalUser) {
      return this.hasRole(user, 'applicant')
    },

    isOrganization (user = this.currentUser) {
      return this.hasRole(user, 'organization')
    },

    isClient (user = this.currentNaturalUser) {
      return this.hasRole(user, 'client') || this.isSubscribedOrganization()
    },

    isStarter (user = this.currentNaturalUser) {
      return this.hasRole(user, 'starter')
    },

    isPremium (user = this.currentNaturalUser) {
      return this.hasRole(user, 'premium')
    },

    // Organizations
    isMemberInOrg ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      return this.hasRole(get(user, `organizations[${get(org, 'id', null)}]`, null), 'member')
    },

    isEditorInOrg ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      return this.hasRole(get(user, `organizations[${get(org, 'id', null)}]`, null), 'editor')
    },

    isAdministratorInOrg ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      // TODO: Fix this one day so dev role is not attributed anymore - UPDATE : not sure since it seems to be default behaviour wanted by Stelace
      return this.hasRole(get(user, `organizations[${get(org, 'id', null)}]`, null), 'administrator') || this.hasRole(get(user, `organizations[${get(org, 'id', null)}]`, null), 'dev') ||
      (this.hasRole(user, 'phenix') || this.hasRole(user, 'dragon') || this.hasRole(user, 'starter') || this.hasRole(user, 'essential') || this.hasRole(user, 'premium'))
    },

    isOrgRootOwner ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      return get(user, 'id') === get(org, 'orgOwnerId')
    },

    isMemberOrAboveInOrg ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      return this.isMemberInOrg({ user, org }) || this.isEditorInOrg({ user, org }) || this.isAdministratorInOrg({ user, org }) || this.isOrgRootOwner({ user, org })
    },

    isEditorOrAboveInOrg ({ user = this.currentNaturalUser, org = this.currentUser } = {}) {
      return this.isEditorInOrg({ user, org }) || this.isAdministratorInOrg({ user, org }) || this.isOrgRootOwner({ user, org })
    },

    // TO CHECK
    isSubscribed (user = this.currentNaturalUser) {
      if (this.isOrganization()) return this.isSubscribedOrganization()
      else return this.$uElements('subscription') ? this.$uElements('subscription') : false
    },
  }
}
