<template>
  <div ref="GridMediaManager" class="fit flex column justify-between items-center">
    <template v-if="arr && arr.length">
      <!-- v-show instead of v-if allow better rendering time when switching draggableActive -->
      <div v-show="!draggableActive" class="row" style="max-height: calc(100% - 55px); overflow-y: auto;">
        <div v-for="(item, index) in $_.compact(arr)" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 q-pa-xs">
          <component
            :is="componentTypes[item.type]"
            :item="item"
            @open="open(item)"
          />
        </div>
      </div>
      <div v-show="draggableActive" style="max-height: calc(100% - 55px); overflow-y: auto;">
        <QBanner inline-actions class="text-black text-weight-bold bg-info q-mb-md">
          Mode de modification de l'ordre actif
          <template #action>
            <HcButton
              label="prompt.leave_button"
              @click="draggableActive = !draggableActive"
            />
          </template>
        </QBanner>
        <draggable v-model="arr" item-key="key" class="row" @change="updateEntity">
          <template #item="{element}">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 q-pa-xs">
              <component
                :is="componentTypes[element.type]"
                :item="element"
                style="cursor: grab !important"
              />
            </div>
          </template>
        </draggable>
      </div>
    </template>
    <AppContent v-else path="component.tools.grid_media.no_results" />
    <div v-if="!readonly" class="row full-width no-wrap flex items-center justify-around q-mt-md q-gutter-x-sm">
      <HcButton
        v-for="(action, a) in footerActions"
        :key="a"
        :label="action.label"
        :loading="loading"
        :disable="loading"
        :variant="action.variant ?? 'primary'"
        class="text-caption"
        @click="open(action)"
      />
    </div>

    <!-- MediaImageDialog -->
    <QDialog v-model="mediaImageDialog" @hide="mediaImageDialog = false">
      <AccountCard path="component.dialogs.media_image_dialog.manage" :bordered="false" icon="uil:image-edit" style="max-height: 90vh; max-width: 90vw">
        <template #header-right>
          <HCButton v-close-popup is-close />
        </template>
        <div class="relative-position">
          <ImageCropperUploader
            v-if="showImageCropper"
            :field="computedField"
            :entity="userCompany"
            @close="showImageCropper = false; mediaImageDialog = false"
            @imageUploaded="uploadImage($event, computedField)"
          />
          <img v-else :src="cdnImg(dataValueImage.key)" loading="lazy" style="width: 100%; max-height: 60vh; object-fit: scale-down;">
          <QBtn
            v-if="!readonly"
            icon="uil:crop-alt"
            :label="$t('component.dialogs.media_image_dialog.crop')"
            class="absolute absolute-bottom-left bg-secondary text-white text-caption q-ma-md"
            @click="showImageCropper = !showImageCropper"
          />
        </div>
        <QCardSection v-if="dataValueImage" class="items-center full-width">
          <QInput v-model="dataValueImage.caption" maxlength="150" counter clearable outlined :readonly="readonly" :label="$t('component.dialogs.media_image_dialog.legend')" debounce="500" @update:model-value="askEmit('edit')" />
        </QCardSection>
        <QCardActions v-if="!readonly" class="items-center justify-between">
          <HcButton
            label="prompt.delete_button"
            variant="negative"
            @click="askEmit('delete'); mediaImageDialog = false"
          />
          <HcButton
            label="prompt.save_button"
            @click="mediaImageDialog = false"
          />
        </QCardActions>
      </AccountCard>
    </QDialog>

    <!-- MediaVideoDialog -->
    <QDialog v-model="mediaVideoDialog" @hide="mediaVideoDialog = false">
      <AccountCard path="component.dialogs.media_video_dialog.manage" :bordered="false" icon="uil:video" style="max-height: 90vh; max-width: 90vw">
        <template #header-right>
          <HCButton v-close-popup is-close />
        </template>
        <LazyYtVideo v-if="dataValueVideo.url" :src="embedURL(dataValueVideo.url)" :ratio="16 / 9" style="width: 100%; max-height: 60vh; object-fit: scale-down;" />
        <QCardSection v-if="dataValueVideo" class="items-center full-width">
          <QInput v-model="dataValueVideo.url" outlined :readonly="readonly" :label="$t('component.dialogs.media_video_dialog.link')" debounce="500" class="q-mb-md" @update:model-value="askEmit('edit')" />
          <QInput v-model="dataValueVideo.caption" clearable outlined :readonly="readonly" :label="$t('component.dialogs.media_video_dialog.legend')" debounce="500" @update:model-value="askEmit('edit')" />
        </QCardSection>
        <QCardActions v-if="!readonly" class="items-center justify-between">
          <QBtn
            icon-right="uil:trash-alt"
            :label="$t('prompt.delete_button')"
            color="negative"
            class="text-caption"
            flat
            @click="askEmit('delete'); mediaVideoDialog = false"
          />
          <QBtn
            :label="$t('prompt.save_button')"
            class="bg-primary text-caption text-white"
            @click="askEmit('edit'); mediaVideoDialog = false"
          />
        </QCardActions>
      </AccountCard>
    </QDialog>

    <!-- MediaAlbumDialog -->
    <QDialog v-model="mediaAlbumDialog" maximized @hide="mediaAlbumDialog = false">
      <AccountCard path="component.dialogs.media_album_dialog.manage" :bordered="false" icon="uil:book-open">
        <template #header-right>
          <div class="flex items-center gt-xs">
            <QInput v-model="dataValueAlbum.name" :label="$t('component.dialogs.media_album_dialog.album_name')" debounce="500" @update:model-value="askEmit('edit')" />
            <HCButton v-close-popup is-close />
          </div>
        </template>

        <div class="row items-center lt-sm">
          <QInput v-model="dataValueAlbum.name" :readonly="readonly" :class="{'col-11': $q.screen.lt.sm}" :label="$t('component.dialogs.media_album_dialog.album_name')" debounce="500" @update:model-value="askEmit('edit')" />
          <HCButton v-close-popup is-close />
        </div>

        <!-- Grid of images of this album -->
        <GalleryManager
          :form-object="formObject"
          :step="step"
          :prop-field="computedField"
          :readonly="readonly"
          style="height:calc(100vh - 80px) !important;"
          @closeAlbum="mediaAlbumDialog = false"
          @updateEntity="updateEntity($event)"
        />
      </AccountCard>
    </QDialog>
  </div>
</template>

<script>
import AwsMixins from 'hc-core/mixins/aws.js'
import draggable from 'vuedraggable'
import { embedURL } from 'hc-core/composables/misc.js'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'
import MediaAlbumCard from 'hc-core/components/cards/media-album-card'
import MediaVideoCard from 'hc-core/components/cards/media-video-card'
import MediaPictureCard from 'hc-core/components/cards/media-picture-card'
import ImageCropperUploader from 'hc-core/components/files/image-cropper-uploader'

export default {
  components: { draggable, LazyYtVideo, MediaAlbumCard, MediaVideoCard, MediaPictureCard, ImageCropperUploader },
  mixins: [AwsMixins],
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },

    // If possible, move this elsewhere, only rely on formObject as reference object and step for editing purposes
    propField: {
      type: Object,
      default: null
    },
  },
  // Only use stepResult and closeAlbum at term
  emits: ['stepResult', 'updateEntity', 'closeAlbum'],
  data () {
    return {
      mediaVideoDialog: false,
      mediaAlbumDialog: false,
      mediaImageDialog: false,
      draggableActive: false,
      computedField: null,
      componentTypes: {
        album: 'MediaAlbumCard',
        video: 'MediaVideoCard',
        image: 'MediaPictureCard',
      },
      arr: [],

      dataValueImage: {
        key: 'platform/branding/placeholder.jpg',
        type: 'image',
        caption: '',
      },
      showImageCropper: false,
      dataValueVideo: {
        url: null,
        type: 'video',
        caption: '',
      },
      dataValueAlbum: {
        name: 'Album sans titre',
        type: 'album',
        caption: '',
      },
      loading: false,
    }
  },
  computed: {
    footerActions () {
      return [
        { label: 'prompt.delete_button', type: 'deleteAlbum', variant: 'negative', if: this.propField },
        { label: 'pages.medias.change_order', type: 'drag', if: !this.draggableActive },
        { label: 'pages.medias.add_photo', type: 'image', if: !this.draggableActive, isNew: true },
        { label: 'pages.medias.add_video', type: 'video', if: !this.draggableActive, isNew: true },
        { label: 'pages.medias.create_album', type: 'album', if: !this.propField && !this.draggableActive, isNew: true },
        // { label: 'import', type: 'import', if: !this.propField && this.$uElements('stats.companies') > 1 && (this.isEditorOrAboveInOrg() || this.isOrgRootOwner()), variant: 'disabled', },
        { label: 'prompt.delete_button', type: 'deleteGeneral', if: !this.readonly && !this.propField && !this.draggableActive, variant: 'negative', }, // DELETE THE WHOLE GALLERY
        { label: 'prompt.save_button', type: 'edit', variant: 'positive', if: this.propField },
      ].filter(a => this.$_.get(a, 'if', true))
    }
  },
  watch: {
    formObject: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
              this.arr = this.$_.clone(this.$_.get(this.step, `copyFromObject.${this.step.field}`, []))
            } else {
              if (this.$_.get(this.propField, 'key', false)) this.arr = this.$_.clone(this.$_.get(this.formObject, `${this.propField.key}.files`, []))
              else this.arr = this.$_.clone(this.$_.get(this.formObject, this.step.field, []))
            }
            this.stepResult()
          })
        }
      }
    },
  },
  methods: {
    embedURL,
    // TYPE DIALOG METHODS
    uploadImage (event) {
      this.$_.set(this.dataValueImage, 'key', this.getFileKey(event))
      this.askEmit('edit')
      this.mediaImageDialog = false
    },
    askEmit (method) {
      if (method === 'delete') {
        this.$q.dialog({
          title: this.$t('component.cards.account_form_card.dialog.title'),
          message: this.$t('component.cards.account_form_card.dialog.message'),
          cancel: { label: this.$t('prompt.cancel_button'), size: 'md', color: 'dark' },
          ok: { label: this.$t('prompt.delete_button'), size: 'md', color: 'negative' },
        }).onOk(async () => {
          try {
            this.updateEntity({
              method,
              field: this.computedField ?? this.propField,
              value: this.mediaImageDialog ? this.dataValueImage : this.mediaVideoDialog ? this.dataValueVideo : this.mediaAlbumDialog ? this.dataValueAlbum : null,
            })
            this.$emit('closeAlbum')
          } catch (e) { this.useLogger(e) }
        })
      } else {
        this.updateEntity({
          method,
          field: this.computedField,
          value: this.mediaImageDialog ? this.dataValueImage : this.mediaVideoDialog ? this.dataValueVideo : this.mediaAlbumDialog ? this.dataValueAlbum : null,
        })
      }
    },

    // GALLERYMANAGER METHODS
    open (item) {
      if (item.type === 'drag') {
        this.draggableActive = !this.draggableActive
      } else if (item.type === 'edit') {
        this.$emit('closeAlbum')
      } else if (item.type === 'deleteAlbum') {
        this.askEmit('delete')
      } else if (item.type === 'deleteGeneral') {
        this.$q.dialog({
          title: this.$t('component.cards.account_form_card.dialog.title'),
          message: this.$t('component.cards.account_form_card.dialog.message'),
          cancel: { label: this.$t('prompt.cancel_button'), size: 'md', color: 'dark' },
          ok: { label: this.$t('prompt.delete_button'), size: 'md', color: 'negative' },
        }).onOk(async () => {
          try {
            this.arr = []
            await this.$store.dispatch('asset/updateCompany', {
              asset: this.formObject,
              attrs: { metadata: { _files: { albums: [] } } }
            })
            this.stepResult()
          } catch (e) { this.useLogger(e) }
        })
      } else {
        this.computedField = this.field(item)
        switch (item.type) {
          case 'video':
            this.dataValueVideo = this.computedField.value
            this.mediaVideoDialog = true
            break
          case 'album':
            this.dataValueAlbum = this.computedField.value
            this.mediaAlbumDialog = true
            break
          case 'image':
            this.dataValueImage = this.computedField.value
            this.mediaImageDialog = true
            if (this.computedField.isNew) this.showImageCropper = true
            break
        }
      }
    },
    // TODO : maybe find a lighter way, but for now it's working
    field (value) {
      if (this.propField) {
        const propFieldCopy = Object.assign({}, this.propField)
        propFieldCopy.isNew = this.$_.get(value, 'isNew', false)

        if ((value.key && value.type !== 'album') || value.url) {
          propFieldCopy.key = `${this.$_.get(propFieldCopy, 'key', '')}.files[${this.$_.get(propFieldCopy, 'value.files', []).indexOf(value)}]`
        } else {
          let newIndex = this.$_.get(propFieldCopy, 'value.files', []).length
          // Fix for multi-add images : check if not writing on existing key
          do {
            newIndex++
          } while (
            propFieldCopy.isNew &&
            (
              this.$_.get(this.propField, `value.files[${newIndex}]`, false) ||
              this.$_.get(this.formObject, `${this.propField.key}.files[${newIndex}]`)
            )
          )
          propFieldCopy.key += `.files[${newIndex}]`
        }

        propFieldCopy.value = this.$_.get(this.formObject, propFieldCopy.key, { caption: '', type: value.type })
        return propFieldCopy
      }

      const key = `metadata._files.albums[${value && Object.keys(value).length > 1
        ? this.$_.get(this.formObject, 'metadata._files.albums', []).indexOf(value) >= 0
          ? this.$_.get(this.formObject, 'metadata._files.albums', []).indexOf(value)
          : this.$_.get(this.formObject, 'metadata._files.albums', []).length
        : this.$_.get(this.formObject, 'metadata._files.albums', []).length}]`

      let uploadFolder = `assets/albums/${this.$_.get(this.formObject, 'metadata._files.key', require('shortid').generate())}`
      if (this.$_.get(value, 'key', false) && value.type === 'image') {
        uploadFolder = value.key.split('/')
        uploadFolder.pop()
        uploadFolder = uploadFolder.join('/')
      }
      if (this.$_.get(value, 'key', false) && value.type === 'album') {
        uploadFolder = `${uploadFolder}/${value.key}`
      }

      return {
        key,
        value,
        uploadFolder,
        isNew: this.$_.get(value, 'isNew', false),
        prefix: 'timestamp',
        icon: 'uil:image-plus', // à adapter
        aspectRatio: Number.NaN, // Allow free aspectRatio
        size: { width: '100%', height: '100%' },
        hint: 'Cliquez ou glissez une image ici', // à adapter
        label: 'Ajouter une image', // à adapter
      }
    },
    async updateEntity (evt) {
      try {
        this.loading = true
        // If sub GalleryManager, emit to parent, else continue
        if (this.propField) { this.$emit('updateEntity', evt); return }

        const entityCopy = Object.assign({}, this.formObject)
        if (!this.$_.get(evt, 'moved', false)) { // Update from components
          // TODO: remove old image from s3
          if (evt.method === 'edit') this.$_.set(entityCopy, evt.field.key, evt.value)
          // TODO: remove old image / album from s3
          if (evt.method === 'delete') this.$_.set(entityCopy, evt.field.key, null)
        }

        if (this.$_.get(evt, 'moved', false)) { // Drag event
          // Files are moved inside an album
          if (this.$_.get(this.computedField, 'value.type', false) === 'album') {
            const albumFiles = this.computedField.value.files
            albumFiles.splice(evt.moved.newIndex, 0, albumFiles.splice(evt.moved.oldIndex, 1)[0])
            this.$_.set(entityCopy, `${this.computedField.key}.files`, albumFiles)
          } else {
            const arr = this.$_.get(entityCopy, 'metadata._files.albums', [])
            if (evt.moved.newIndex >= arr.length) {
              let k = evt.moved.newIndex - arr.length + 1
              while (k--) { arr.push(undefined) }
            }
            arr.splice(evt.moved.newIndex, 0, arr.splice(evt.moved.oldIndex, 1)[0])
            this.$_.set(entityCopy, 'metadata._files.albums', arr)
          }
        }

        // Ensure only storing authorized keys
        // Prevent draggable from inserting undefined or null values
        this.arr = this.$_.compact(this.$_.reduce(this.$_.get(entityCopy, 'metadata._files.albums', []), (accumulator, item) => {
          if (item && ((item.type === 'video' && item.url) || (item.type === 'image' && item.key) || (item.type === 'album'))) {
            if (item.files) item.files = this.$_.compact(item.files)
            for (const notAuthK of this.$_.difference(Object.keys(item), ['url', 'caption', 'key', 'files', 'name', 'type'])) {
              delete item[notAuthK]
            }
            accumulator.push(item)
          }
          return accumulator
        }, []))

        // EXCEPTION : since this is a very complex step, trigger save each time (only root GalleryManager)
        await this.$store.dispatch('asset/updateCompany', {
          asset: this.formObject,
          attrs: { metadata: { _files: { albums: this.arr } } }
        })
        this.stepResult()
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.arr)
      const canGoNext = this.$_.get(this.arr, 'length', 0) > 0 || this.$_.get(this.step, 'optional', false)
      this.$emit('stepResult', { canGoNext, value })
    }
  },
}
</script>
