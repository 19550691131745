<template>
  <QDialog ref="MediaVideoDialog" @hide="$emit('close')">
    <AccountCard path="component.dialogs.media_video_dialog.manage" :bordered="false" icon="uil:video" style="max-height: 90vh; max-width: 90vw">
      <template #header-right>
        <HCButton v-close-popup is-close />
      </template>
      <LazyYtVideo v-if="dataValue.url" :src="embedURL(dataValue.url)" :ratio="16 / 9" style="width: 100%; max-height: 60vh; object-fit: scale-down;" />
      <QCardSection v-if="dataValue" class="items-center full-width">
        <QInput v-model="dataValue.url" outlined :readonly="readonly || !isEditorOrAboveInOrg()" :label="$t('component.dialogs.media_video_dialog.link')" debounce="500" class="q-mb-md" @update:modelValue="askEmit('edit')" />
        <QInput v-model="dataValue.caption" clearable outlined :readonly="readonly || !isEditorOrAboveInOrg()" :label="$t('component.dialogs.media_video_dialog.legend')" debounce="500" @update:modelValue="askEmit('edit')" />
      </QCardSection>
      <QCardActions v-if="!readonly && isEditorOrAboveInOrg()" class="items-center justify-between">
        <HcButton
          label="prompt.delete_button"
          variant="negative"
          class="text-caption"
          @click="askEmit('delete'), $emit('close')"
        />
        <HcButton
          label="prompt.save_button"
          class="text-caption"
          @click="askEmit('edit'), $emit('close')"
        />
      </QCardActions>
    </AccountCard>
  </QDialog>
</template>

<script>
import { embedURL } from 'hc-core/composables/misc.js'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'

export default {
  components: { LazyYtVideo },
  props: {
    field: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateEntity', 'close'],
  data () {
    return {
      dataValue: {
        url: 'platform/branding/placeholder.jpg',
        type: 'video',
        caption: '',
      },
    }
  },
  mounted () {
    if (this.field.value) this.dataValue = this.field.value
    this.$refs.MediaVideoDialog.show()
  },
  methods: {
    embedURL,
    askEmit (method) {
      this.$emit('updateEntity', {
        method,
        field: this.field,
        value: this.dataValue,
        entity: this.userCompany,
      })
    }
  }
}
</script>
