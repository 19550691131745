<template>
  <component
    :is="variants[props.variant]"
    :class="`text-${Object.keys(variants).includes(props.variant) ? props.variant : 'body1'}`"
  >
    {{ computedLabel(props.label) }}
  </component>
</template>

<script setup>
import { computedLabel } from 'hc-core/composables/intl'

const variants = {
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3',
  'h4': 'h4',
  'h5': 'h5',
  'h6': 'h6',
  'subtitle1': 'div',
  'subtitle2': 'div',
  'body1': 'div',
  'body1-bold': 'div',
  'body2': 'div',
  'body2-bold': 'div',
  'caption': 'span',
  'overline': 'span',
}

const props = defineProps({
  variant: {
    type: String,
    default: 'body1'
  },
  label: [String, Object],
})
</script>
