import { findLast, get, isMatch, sortBy } from 'lodash'
import { useStore } from 'vuex'
import { computed } from 'vue'

// Mainly to replace roles.js mixins
export function usePerms (storeParam = undefined) {
  const store = storeParam ?? useStore() // Param allow to be used in vue-router

  // Computed
  const currentUser = computed (() => store.getters['auth/currentUser'])
  const userOrganizations = computed (() => store.getters['auth/userOrganizations'])
  const currentNaturalUser = computed (() => store.getters['auth/currentNaturalUser'])

  // Old way, maybe be slowly replaced by usePerm
  const hasRole = (user, role) => { return user && role && get(user, 'roles', []).includes(role) }

  // Only in organization context
  const isSubscribedOrganization = () => { return get(userOrganizations.value, 'subscription', false) }

  // General roles
  const isApplicant = (user = currentNaturalUser.value) => { return hasRole(user, 'applicant') }
  const isOrganization = (user = currentUser.value) => { return hasRole(user, 'organization') }
  const isClient = (user = currentNaturalUser.value) => { return hasRole(user, 'client') || isSubscribedOrganization() }
  const isStarter = (user = currentNaturalUser.value) => { return hasRole(user, 'starter') }
  const isPremium = (user = currentNaturalUser.value) => { return hasRole(user, 'premium') }

  // From previously fetched HC permissions array, check if param permission is doable
  // Bonus : check for slots, offerCredits and addressCredits too if need
  // TODO : usable after updating roles in prod with adding hc permissions (+ in private config)
  // usePerm (permission, additionalCheck = true) {
  //   const hcPerms = this.$store.getters['auth/hcPerms']
  //   if (!hcPerms.length || !permission) return false

  //   let hasPerm = hcPerms.includes(permission)

  //   const requiresAdditionalCheck = ['slots', 'offerCredits', 'addressCredits']
  //   const additionalCase = last(permission.split(':'))
  //   if (hasPerm && additionalCheck && requiresAdditionalCheck.includes(additionalCase)) {
  //     // TODO : finish here the checks
  //     if (additionalCase === 'addressCredits') {
  //       const addressesUsed = flattenDeep(this.$store.getters['auth/uElements']('companies').map(c => c.locations)).length
  //       return addressesUsed <= this.$store.getters['auth/uElements']('addressCredits')
  //     } else {
  //       hasPerm = hasPerm && additionalCheck
  //     }
  //   }
  //   return hasPerm
  // },

  // ############# DEV ONLY BELOW ###############""
  const rootUser = computed(() => store.getters['auth/uElements']('rootUser'))
  const ALL_SUBSCRIPTIONS = computed(() => {
    // https://stackoverflow.com/a/28719938
    const toKeep = ['free_trial', 'pay_as_you_go', 'membership', 'spark', 'phenix', 'dragon', 'starter', 'essential', 'premium']
    return sortBy(
      get(store.state.content, 'shop.results', []).filter(item => toKeep.includes(item.name)),
      (item) => toKeep.indexOf(item.name)
    )
  })
  const rootUserSubscription = computed(() => findLast(ALL_SUBSCRIPTIONS.value, (s) => isMatch(rootUser.value, get(s, 'metadata._private.conditions', { prevent: 'matching' }))))
  const rootUserFeatures = computed(() => rootUserSubscription.value ? get(rootUserSubscription.value, 'fields.features', []) : [])
  const hasPerm = (permission, level = 0) => {
    return !permission ? false : get(rootUserFeatures.value.find(f => f.name === permission), 'level', 0) >= level
  }
  const allPermsValid = (requiredPerms) => {
    return requiredPerms.reduce((acc, requiredPerm) => acc && hasPerm(requiredPerm.name, requiredPerm.level ?? 0), true)
  }

  return {
    // Computed
    currentUser,
    userOrganizations,
    currentNaturalUser,

    // Functions
    isApplicant,
    isOrganization,
    isClient,
    isStarter,
    isPremium,

    // DEV ONLY
    rootUserSubscription,
    rootUserFeatures,
    hasPerm,
    allPermsValid
  }
}
