<template>
  <QDialog ref="MediaAlbumDialog" maximized @hide="$emit('close')">
    <AccountCard path="component.dialogs.media_album_dialog.manage" :bordered="false" icon="uil:book-open">
      <template #header-right>
        <div class="flex items-center gt-xs">
          <QInput v-model="dataValue.name" :label="$t('component.dialogs.media_album_dialog.album_name')" debounce="500" @update:modelValue="askEmit('edit')" />
          <HCButton v-close-popup is-close />
        </div>
      </template>

      <div class="row items-center lt-sm">
        <QInput v-model="dataValue.name" :readonly="readonly || !isEditorOrAboveInOrg()" :class="{'col-11': $q.screen.lt.sm}" :label="$t('component.dialogs.media_album_dialog.album_name')" debounce="500" @update:modelValue="askEmit('edit')" />
        <HCButton v-close-popup is-close />
      </div>

      <!-- Grid of images of this album -->
      <GridMediaManager
        type="gallery"
        :medias="$_.get(field, 'value.files', [])"
        :grid-method="gridMethod"
        :prop-field="field"
        style="overflow-y: scroll; max-height:calc(100% - 115px)"
        @resetGridMethod="gridMethod = null"
      />

      <QCardActions v-if="!readonly && isEditorOrAboveInOrg()" :class="['items-center', {'justify-center': $q.screen.lt.sm}, {'justify-between': $q.screen.gt.xs}]">
        <HcButton
          label="prompt.delete_button"
          variant="negative"
          class="text-caption"
          @click="askEmit('delete'), $emit('close')"
        />
        <div class="q-gutter-x-sm">
          <HcButton
            label="pages.medias.change_order"
            class="text-caption"
            @click="gridMethod = 'changeOrder'"
          />
          <HcButton
            label="component.dialogs.media_album_dialog.add_photo"
            class="text-caption"
            @click="gridMethod = 'addPicture'"
          />
          <HcButton
            label="component.dialogs.media_album_dialog.add_video"
            class="text-caption"
            @click="gridMethod = 'addVideo'"
          />
        </div>
        <HcButton
          label="prompt.save_button"
          class="text-caption"
          @click="askEmit('edit'), $emit('close')"
        />
      </QCardActions>
    </AccountCard>
  </QDialog>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateEntity', 'close'],
  data () {
    return {
      gridMethod: null,
      dataValue: {
        name: 'Album sans titre',
        type: 'album',
        caption: '',
      },
    }
  },
  mounted () {
    if (this.field.value) this.dataValue = this.field.value
    this.$refs.MediaAlbumDialog.show()
  },
  methods: {
    askEmit (method) {
      this.$emit('updateEntity', {
        method,
        field: this.field,
        value: this.dataValue,
        entity: this.userCompany,
      })
    }
  },
}
</script>
