<template>
  <QChip
    outline
    :color="props.color"
    :size="props.size"
    :icon="props.icon"
    :removable="props.removable"
    :clickable="props.clickable"
    :icon-right="props.iconRight"
    :text-color="props.textColor"
    :label="props.label ? computedLabel(props.label) : undefined"
    @remove="emit('remove')"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </QChip>
</template>

<script setup>
import { computedLabel } from 'hc-core/composables/intl'

const props = defineProps({
  label: {
    type: [String, Object],
    default: undefined
  },
  color: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'md'
  },
  icon: String,
  iconRight: String,
  textColor: {
    type: String,
    default: 'black'
  },
  removable: {
    type: Boolean,
    default: false
  },
  clickable: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits('remove')
</script>
