import stelace from 'hc-core/composables/stelace'

export async function initApp ({ dispatch }) {
  await dispatch('common/fetchConfig', undefined, { root: true })
  await dispatch('asset/fetchAssetTypes', undefined, { root: true })
  await dispatch('common/fetchCategories', undefined, { root: true })
  await dispatch('common/fetchCustomAttributes', undefined, { root: true })

  // DEV ONLY (?)
  await dispatch('content/list', { collection: 'shop', nbResultsPerPage: 20 }, { root: true })
}

export async function fetchConfig ({ commit }) {
  const config = await stelace.config.read()
  commit('common/setConfig', { config }, { root: true })
  return config
}

export async function fetchCategories ({ commit }) {
  const categories = await stelace.categories.list()
  commit('common/setCategoriesById', { categories }, { root: true })
  return categories
}

export async function fetchCustomAttributes ({ commit }) {
  const customAttributes = await stelace.customAttributes.list({ nbResultsPerPage: 100 })
  commit('common/setCustomAttributes', { customAttributes }, { root: true })
  return customAttributes
}

export async function sendCustomEvent (context, { type, objectId, emitterId = 'happycab-v3', metadata }) {
  await stelace.events.create({
    type,
    objectId,
    emitterId,
    metadata
  })
}

export async function salaryConverter (context, { amount = 19800, unit = 'yearly', tax = 'gross' }) {
  return await stelace.forward.post('/advanced/salaryconverter', { amount, unit, tax })
}
