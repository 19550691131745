<template>
  <QInput
    ref="inputRef"
    v-model="cptValue"
    :color="props.color"
    :readonly="props.readonly ?? false"
    :label="props.label ? computedLabel(props.label) : undefined"
    :hint="props.hint ? computedLabel(props.hint) : undefined"
    :loading="props.loading"

    :type="props.type"
    :rules="props.rules"
    :mask="props.mask"
    :autogrow="!!(props.autogrow)"
    :clearable="!!(props.clearable)"
    :suffix="props.suffix"

    :bottom-slots="!!(props.hint || props.counter || props.maxLength || props.rules.length)"
    :counter="!!(props.counter || props.maxLength)"
    :maxlength="props.maxLength"

    bg-color="white"
    class="br-8"
    :class="{ 'text-negative': get(inputRef, 'hasError', false) }"
    debounce="300"
    outlined
    lazy-rules
    hide-hint
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </QInput>
</template>

<script setup>
import { get } from 'lodash'
import { computed, ref } from 'vue'
import { computedLabel } from 'hc-core/composables/intl'

const props = defineProps({
  modelValue: {
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  label: [String, Object],
  hint: [String, Object],
  color: {
    type: String,
    default: undefined
  },
  loading: {
    type: Boolean,
    default: false
  },

  // Input
  type: {
    type: String,
    default: 'text'
  },
  rules: {
    type: Array,
    default: () => []
  },
  mask: {
    type: String,
    default: undefined
  },
  autogrow: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  suffix: {
    type: String,
    default: undefined
  },

  // Bottom slots
  counter: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: undefined
  }
})
const emit = defineEmits(['update:modelValue'])
const cptValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
const inputRef = ref(null)
</script>
