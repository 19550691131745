<template>
  <HcInput
    v-model="cptValue"
    :type="show.pwd ? 'text' : 'password'"
    label="user.password"
    :rules="[requiredFieldRule, isPwd8LongRule]"
    @focus="show.rules = true"
    @blur="show.rules = false"
  >
    <template #append>
      <QIcon
        :name="show.pwd ? 'visibility_off' : 'visibility'"
        class="cursor-pointer"
        @click="show.pwd = !show.pwd"
      />
    </template>
    <template
      v-if="show.rules"
      v-slot:hint
    >
      <div class="flex items-center">
        <QIcon
          size="sm"
          :name="isPwd8Long(cptValue) ? 'uil:check' : 'uil:times'"
          :color="isPwd8Long(cptValue) ? 'positive' : 'negative'"
        />
        <span class="q-ml-sm">Minimum 8 caractères</span>
      </div>
    </template>
  </HcInput>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useValidation } from 'hc-core/composables/useValidation'
const { requiredFieldRule, isPwd8Long, isPwd8LongRule } = useValidation()

const show = ref({
  pwd: false,
  rules: false
})

const props = defineProps({
  modelValue: {
    required: true
  },
})

const emit = defineEmits(['update:modelValue'])
const cptValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>
