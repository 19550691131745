import stelace from 'hc-core/composables/stelace'

export async function fetchi18nContent ({ commit }, { locale }) {
  commit('content/setLocale', { locale }, { root: true })
  await import(/* webpackChunkName: 'i18n-stl-[request]' */`../../../../../lib/i18n/build/${locale}.json`).then(translations => {
    commit('content/seti18nContent', { content: translations.default }, { root: true })
  })
}

// ##### Generic calls #####

export async function list ({ commit }, { page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', collection = undefined, locale = 'fr', id = undefined, metadata = undefined, getResults = true }) {
  const arr = await stelace.forward.get('/entries/advanced', {
    page,
    nbResultsPerPage,
    orderBy,
    order,
    locale,
    id,
    metadata,
    collection,
  })
  commit('content/setEntries', { key: collection, arr }, { root: true })
  return getResults ? arr.results : arr
}

export async function read (context, { id }) {
  return await stelace.entries.read(id)
}

// Filtering from documents of type data
export async function getDataLabelOptions (context, { label = undefined, query = undefined, key = undefined }) {
  if (!label) return []
  return await stelace.forward.get('/data/options', { query, label, key })
}
