<template>
  <QSelect
    v-model="cptValue"

    :options="cptOptions"
    :map-options="props.mapOptions"
    :emit-value="props.emitValue"
    :option-value="props.optionValue"
    :option-label="props.optionLabel"
    :multiple="props.multiple"
    :use-input="props.useInput"
    input-debounce="350"

    :disable="props.disable"
    :readonly="props.readonly"
    :clearable="props.clearable"
    :label="computedLabel(props.label)"
    :display-value="props.displayValue ? computedLabel(props.displayValue) : undefined"
    :hint="props.hint ? computedLabel(props.hint) : undefined"
    :bottom-slots="!!(props.bottomSlots || props.hint || props.counter || props.rules.length)"
    dropdown-icon="uil:angle-down"

    outlined
    class="br-8"
    :bg-color="props.bgColor"
    :label-color="props.labelColor"

    @filter="props.filterFn"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </QSelect>
</template>

<script setup>
import { computed } from 'vue'
import { compact } from 'lodash'
import { computedLabel } from 'hc-core/composables/intl'
import { arrayIfFilter } from 'hc-core/composables/misc.js'

const props = defineProps({
  // Model and options
  modelValue: {
    required: true
  },

  // Options
  options: {
    type: Array,
    default: () => []
  },
  mapOptions: {
    type: Boolean,
    default: true
  },
  emitValue: {
    type: Boolean,
    default: true
  },
  optionValue: {
    type: String,
    default: undefined
  },
  optionLabel: {
    type: String,
    default: undefined
  },
  multiple: {
    type: Boolean,
    default: false
  },
  useInput: {
    type: Boolean,
    default: false
  },
  filterFn: {
    type: Function,
    default: undefined
  },

  // State
  disable: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  label: {
    type: [String, Object],
    default: null
  },
  hint: {
    type: [String, Object],
    default: null
  },
  displayValue: {
    type: String,
    default: undefined
  },
  bottomSlots: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array,
    default: () => []
  },
  counter: {
    type: Boolean,
    default: false
  },

  // Styling
  bgColor: {
    type: String,
    default: undefined
  },
  labelColor: {
    type: String,
    default: undefined
  },
})
const emit = defineEmits(['update:modelValue'])
const cptValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
const cptOptions = computed(() => {
  return arrayIfFilter(compact(props.options.map((option) => {
    const supposedLabel = option[props.optionLabel ?? 'label']
    if (supposedLabel) option[props.optionLabel ?? 'label'] = computedLabel(supposedLabel)
    return option
  })))
})
</script>
