import { isNil } from 'lodash'
import { useStore } from 'vuex'
import { $t } from 'hc-core/composables/intl'

export function useValidation () {
  // Helpers
  const store = useStore()

  // Generic
  const requiredField = (val) => {
    return !isNil(val) && val !== '' && !!val
  }
  const requiredFieldRule = (val) => {
    return requiredField(val) || $t('form.error.missing_field')
  }

  // Email
  const isValidEmail = (val) => {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{2,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
    return emailPattern.test(val)
  }
  const isValidEmailRule = (val) => {
    return isValidEmail(val) || $t('form.error.invalid_email')
  }
  const isEmailAvailable = async (val) => {
    return val && await store.dispatch('auth/checkAvailability', { username: val })
  }
  const isEmailAvailableRule = async (val) => {
    return await isEmailAvailable(val) || $t('authentication.error.email_already_used')
  }

  // Password
  // Field validation : Check if field has at least 8 characters
  const isPwd8Long = (val) => {
    return val && val.length >= 8
  }
  const isPwd8LongRule = (val) => {
    return isPwd8Long(val) || 'Minimum 8 caractères'
  }


  // Phone
  const isValidPhone = (val) => {
    const phonePatternTrim = /^[0-9]{10,10}$/
    const phonePatternSplit = /^(\s*\d{2}){5}$/
    return val && (phonePatternTrim.test(val) || phonePatternSplit.test(val))
  }
  const isValidPhoneRule = (val) => {
    return isValidPhone(val) || $t('form.error.invalid_phone')
  }

  return {
    requiredField,
    requiredFieldRule,
    isValidEmail,
    isValidEmailRule,
    isEmailAvailable,
    isEmailAvailableRule,
    isPwd8Long,
    isPwd8LongRule,
    isValidPhone,
    isValidPhoneRule,
  }
}
