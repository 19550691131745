import { copyToClipboard } from 'quasar'
import { chain, compact, get, set, isEmpty, isEqual, isNil, isObject, isString, omit, pickBy, capitalize, startCase, transform } from 'lodash'

export const timer = ms => new Promise(resolve => setTimeout(resolve, ms))

// User related
export const userMetadataMapping = {
  avatarUrl: 'metadata.instant.avatarUrl',
  publicName: 'metadata.instant.publicName',
  profileTitle: 'metadata.instant.profileTitle',
  locations: 'metadata.instant.locations',
  images: 'metadata.images',
  averageRating: 'metadata.instant.averageRating',
  score: 'metadata.instant.score',
  firstName: 'metadata._premium.firstname',
  lastName: 'metadata._premium.lastname',
  phone: 'metadata._private.phone',
  newEmail: 'platformData._private.newEmail',
  emailVerified: 'platformData.instant.emailVerified',
}

export function getDisplayName (firstname, lastname) {
  if (!firstname) return ''
  let displayName = firstname
  if (lastname) displayName += ` ${(lastname || '').charAt(0)}.`
  return displayName
}

// Geopattern alternative
export function bgGradient (str) {
  return `background-image: linear-gradient(to bottom right, ${getBackgroundColor(str)}, ${getBackgroundColor(`hc${str}hc`)})`
}

export function getBackgroundColor (stringInput = 'happycab') {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  return `hsl(${stringUniqueHash % 360}, 95%, 50%)`
}

// Embed Youtube
export function embedURL (url) {
  return url.includes('embed') ? url : `https://www.youtube.com/embed/${youtubeParser(url)}`
}

export function youtubeParser (url) {
  const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/)
  return (match && match[7].length === 11) ? match[7] : false
}

export function escapeUrl (url) { return url ? url.includes('http') ? url : `https://${url}` : null }

// Clipboard
export async function cc (content) {
  copyToClipboard(content)
}

// Newsletter
function isEmail (value) {
  const emailRegex = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/
  return isString(value) && emailRegex.test(value)
}

export async function addContactToNewsletter (email, firstname) {
  if (isEmail(email) && !isEmpty(firstname)) {
    const req = {
      type: 'newsletter_subscribe',
      metadata: { email, firstname, type: process.env.HC_PLATFORM.toLowerCase() }
    }
    if (this.currentNaturalUser && this.currentNaturalUser.id) req.objectId = this.currentNaturalUser.id
    await this.$store.dispatch('common/sendCustomEvent', req)
    this.notifySuccess('newsletter.success_email')
  } else {
    this.notifyError('newsletter.error_email')
  }
}

// AccountCard and derivates saved signal
export function useSavedSignal () {
  this.savedSignal = false
  this.$nextTick(() => {
    this.savedSignal = true
    setTimeout(() => { this.savedSignal = false }, 3700)
  })
}

// Files
// TODO : better to import normalizeString from stelace/util/hc_helpers
export function sanitizeFilename (val) {
  return val.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '_').replace(/'/g, '_')
}

// Locations
export function getLocationLabel (loc) {
  if (!loc) return '-'
  const street = get(loc, 'street', false)
  const postcode = get(loc, 'postcode', false)
  const city = get(loc, 'city', false)
  const country = get(loc, 'country', false)
  return `${street ? `${street}, ` : ''}${postcode ? `${postcode} ` : ''}${city ? `${city}` : ''}${country ? `, ${country === 'F' ? 'France' : country}` : ''}`
}

// JS
export const deepKeys = (t, path = []) => {
  return Object(t) === t && !Array.isArray(t) ? Object.entries(t).flatMap(([k, v]) => deepKeys(v, [...path, k])) : [path.join('.')]
}

export function stepResultExtractor (object, base) {
  const r = {}
  for (const path of deepKeys(object)) {
    if (!isEqual(get(object, path), get(base, path))) set(r, path, get(object, path))
  }
  return r !== {} ? r : false
}

export function capitalizeFirstLetter (str) {
  return capitalize(str)
}

export function ensureParamToArray (param) {
  return compact(param.toString().replace(/"|\[|\]/g, '').split(','))
}

export function stripTags (val, allowedTags = '<p><a><br><br/><h1><h2><h3><h4><h5><h6>') {
  if (val && typeof val === 'string') {
    const allowed = (((allowedTags || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return val.replace(tags, function ($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
    })
  } else return val
}

export function compactObjectDeep (request) {
  return pickBy(request, (value) => {
    return isObject(value) ? !isEmpty(compactObjectDeep(value)) : isDefined(value)
  })
}

export function deepClean (obj) {
  return transform(obj, (result, value, key) => {
    if (isObject(value)) {
      const compactedValue = deepClean(value)
      if (!isEmpty(compactedValue)) {
        result[key] = compactedValue
      }
    } else if (isDefined(value) && value) {
      result[key] = value
    }
  })
}

export function hexToEmo (hex = '1f381') {
  if (!isString(hex)) hex = '1f381'
  // Ensure compounded emojis are rendered - https://stackoverflow.com/a/72563636
  return hex.split('-').map(e => String.fromCodePoint(`0x${e}`)).join('')
}

export function emoToHex (emo = '🎁') {
  // Ensure compounded emojis are rendered - https://stackoverflow.com/a/72563636
  return [...emo].map(e => e.codePointAt(0).toString(16)).join('-')
}

// Imported from stelace/plugins/xml/services
export function isDefined (v) { return !isNil(v) && v !== '' }
export function pickFirstKey (obj, keysToCheck, defaultValue = null) {
  return chain(keysToCheck).map(key => get(obj, key)).find(v => isDefined(v)).defaultTo(defaultValue).value()
}

export function arrayIfFilter (a) { return !Array.isArray(a) ? a : a.filter(i => get(i, 'if', true)) }

export function replaceInArray (array, items, property) {
  const newArray = array
  for (const item of ensureParamToArray(items)) {
    const index = newArray.findIndex((arrayItem) => arrayItem[property] === item[property])
    if (index) set(newArray, `[${index}]`, item)
  }
  return newArray
}

// Dirty way but we do it for HCTable, since stelace package remap it
export function remapToPaginated (res) {
  return {
    results: res.results ?? Object.values(omit(res, ['paginationMeta', 'lastResponse'])),
    page: get(res, 'paginationMeta.page', 0),
    nbPages: get(res, 'paginationMeta.nbPages', 0),
    nbResults: get(res, 'paginationMeta.nbResults', 0),
    nbResultsPerPage: get(res, 'paginationMeta.nbResultsPerPage', 0),
  }
}

export function startCaseUser (usr) {
  set(usr, 'firstname', startCase(usr.firstname))
  set(usr, 'lastname', startCase(usr.lastname))
  set(usr, 'displayName', startCase(usr.displayName))
  return usr
}

export function openMapsOnLocation (location) {
  if (get(location, 'latitude', false) && get(location, 'longitude', false)) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`, '_blank')
  }
}

// Try to get the key (or the first one) and return undef is not defined
export function getOrUndef (obj, k) {
  const v = Array.isArray(k) ? pickFirstKey(obj, k, undefined) : get(obj, k, undefined)
  return isDefined(v) ? v : undefined
}

// Only used in fetchFullCompany / TODO : find a way to get rid of it
export function companyTeamNumber (context, { company }) {
  function getNb (company, getAverage) {
    const headcount = get(company, 'metadata._team.headcount', null)
    // const range = get(company, 'metadata._team.headcountRange', null) ? this.$t({ id: `component.selectors.headcount_range_selector.nb.${get(company, 'metadata._team.headcountRange')}` }) : null
    let range = get(company, 'metadata._team.headcountRange', null) ? get(company, 'metadata._team.headcountRange') : null
    if (range && range.value) range = range.value
    const average = range ? Math.round((parseInt(range.split('_')[0]) + parseInt(range.split('_')[1])) / 2) : null

    if (get(company, 'metadata._team.headcountPrecision', false)) return { type: 'integer', nb: parseInt(headcount) }
    else return (getAverage ? { type: 'average', nb: parseInt(average) } : { type: 'range', nb: range }) || null
  }

  if (get(company, ('implantations[0]'), false) && company.assetTypeId === get(context, 'rootGetters[\'common/brandTypeId\']', false)) {
    let nb = 0
    let tmp = 0
    let approx = false
    if (get(company, 'platformData.options.reference', false) && get(company.implantations.filter((imp) => imp.id === company.platformData.options.reference), '[0]', false)) {
      tmp = getNb(company.implantations.filter((imp) => imp.id === company.platformData.options.reference)[0], false)
      if (tmp.nb) {
        if (tmp.type === 'integer') nb += tmp.nb
        else {
          approx = true
          nb += tmp.nb
        }
      }
    } else {
      company.implantations.forEach((imp) => {
        tmp = getNb(imp, true)
        if (tmp.nb) {
          if (tmp.type === 'integer') nb += tmp.nb
          else {
            approx = true
            nb += tmp.nb
          }
        }
      })
    }
    if (nb !== 0 && !approx) return { type: 'integer', nb }
    else if (nb !== 0 && approx) return { type: 'integer', nb: `Environ ${nb}` }
  } else return getNb(company, false)
}
